import axios from "axios";
import { getAuthToken } from "../../store/sagas/authSaga";
import config from "../config";
import url, { handleGetFirebaseToken } from "../../api";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

const fetchAuthHeaders = async () => {
  const { jwtToken, issue } = await getAuthToken();
  return {
    "X-Cognito-Issuer": issue,
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwtToken}`,
  };
};

export const getPrecinct = async () => {
  if (localStorage.getItem("precinct")) {
    const { precinct_id, precinct_name } = JSON.parse(
      localStorage.getItem("precinct")
    );
    return { precinct_id, precinct_name };
  } else {
    try {
      const { jwtToken, issue, user_id } = await getAuthToken();
      const _dispatcher: any = await axios.get(
        `${config.baseUrl}/dispatchers/${user_id}`,
        {
          headers: {
            "X-Cognito-Issuer": issue,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (_dispatcher?.data?.data?.dispatcher) {
        const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;
        localStorage.setItem(
          "precinct",
          JSON.stringify({ precinct_id, precinct_name })
        );

        const firebasePermissionToken = localStorage.getItem(
          "firebasePermissionToken"
        );
        if (!firebasePermissionToken) {
          handleGetFirebaseToken();
        }

        return { precinct_id, precinct_name };
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getDispatcher = async () => {
  if (localStorage.getItem("dispatcherData")) {
    const dispatcher = JSON.parse(localStorage.getItem("dispatcherData"));
    return dispatcher;
  } else {
    try {
      const { jwtToken, issue, user_id } = await getAuthToken();
      const response: any = await axios.get(
        `${config.baseUrl}/dispatchers/${user_id}`,
        {
          headers: {
            "X-Cognito-Issuer": issue,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          validateStatus: () => true,
        }
      );
      if (response?.data) {
        localStorage.setItem("dispatcherData", JSON.stringify(response.data));
      }
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
};

export const verifyNHIS = async (phone) => {
  const apiKey = process.env.REACT_APP_NHIS_API_KEY;
  try {
    const response = await axios.get(
      `http://digicomme.com:8000/api/prz/enrollees/${phone}/verify`,
      {
        headers: {
          "api-key": apiKey,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDistance = async (origin, destinations) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API || "";

  const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destinations}&key=${API_KEY}`;
  const resp = await fetch(url, { method: "GET" });
  const result = await resp.json();

  if (result.status === "OK") {
    const { rows } = result;
    return rows;
  }
  const error = new Error(result.error_message);
  error.name = result.status;
  throw error;
};

export const getNotifications = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;
      endpoint = `notifications?entity=dispatcher&limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `notifications?entity=dispatcher&limit=0`;
    }

    const response = await url.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    return response?.data?.data?.notifications;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch notifications");
  }
};

export const getInquiries = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();
    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;
      endpoint = `inquiry?limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `inquiry?limit=0`;
    }

    const response = await url.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch inquiries");
  }
};

export const getCaseByID = async (caseId) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await url.get(`${config.baseUrl}/cases/${caseId}`, {
      headers,
      validateStatus: () => true,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCases = async (pageNumber = 1) => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `entities?entity=cases&task=trim&skip_cache=true&numberPerPage=10&pageNumber=${pageNumber}&precinct_id=${precinct_id}`;
    } else {
      endpoint = `entities?entity=cases&task=trim&skip_cache=true&numberPerPage=10&pageNumber=${pageNumber}`;
    }

    const response = await url.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch cases");
  }
};

export const searchAllCases = async (
  searchParam: string,
  searchValue: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `cases?precinct_id=${precinct_id}&search_field=${searchParam}&search_term=${searchValue}`;
    } else {
      endpoint = `cases?search_field=${searchParam}&search_term=${searchValue}`;
    }

    const response = await url.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch cases");
  }
};

export const getAmbRequestsDashboard = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `entities?entity=ambulance-requests&task=trim&numberPerPage=50&precinct_id=${precinct_id}`;
    } else {
      endpoint = `entities?entity=ambulance-requests&task=trim&numberPerPage=50`;
    }

    const response = await url.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch ambulance requests");
  }
};

export const getIncidentReportByID = async (caseId) => {
  try {
    const headers = await fetchAuthHeaders();

    const response = await url.get(
      `${config.baseUrl}/incident-reports?case_id=${caseId}`,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getReferences = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.get(
      `${config.baseUrl}/references?task=get_reference`,
      {
        headers,
        validateStatus: () => true,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getIncidentTypes = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.get(
      `${config.baseUrl}/references?task=get_incident_types`,
      {
        headers,
        validateStatus: () => true,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getProvisionalDiagnosis = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.get(
      `${config.baseUrl}/references?task=get_provisional_diagnisis`,
      {
        headers,
        validateStatus: () => true,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getActionTaken = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.get(
      `${config.baseUrl}/references?task=get_action_taken`,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProbableCause = async (code: string) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.get(
      `${config.baseUrl}/references?task=get_probable_cause&provisional_diag=${code}`,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDiagnosis = async (diagnosis: string, severity: string) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/recommendation/diagnosis`,
      { provisional_diagnosis: diagnosis, severity },
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const approveCase = async (
  caseId: string,
  amb_transporter_id: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/cases/${caseId}/approve`,
      { amb_transporter_id },
      {
        headers,
        validateStatus: () => true,
      }
    );

    if (response?.status !== 200) {
      throw new Error("Failed to approve case");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to approve case");
  }
};

export const createCase = async (caseData: object) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(`${config.baseUrl}/cases`, caseData, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to create case");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to create case");
  }
};

export const uploadCase = async (caseData) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/cases/upload`,
      caseData,
      {
        headers,
        validateStatus: () => true,
      }
    );

    if (response?.status !== 200) {
      throw new Error("Failed to upload case");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to upload case");
  }
};

export const initiateUssdCase = async (caseId: string) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.put(
      `${config.baseUrl}/cases/${caseId}/makeoffline`,
      null,
      {
        headers,
        validateStatus: () => true,
      }
    );

    if (response?.status !== 200) {
      throw new Error("Failed to update case");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to update case");
  }
};

export const updateCase = async (caseId: string, caseData: object) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.put(
      `${config.baseUrl}/cases/${caseId}`,
      caseData,
      {
        headers,
        validateStatus: () => true,
      }
    );

    if (response?.status !== 200) {
      throw new Error("Failed to update case");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to update case");
  }
};

export const closeCase = async (
  caseId: string,
  dispatcherID: string,
  caseData: object
) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/cases/${caseId}/close`,
      {
        dispatcherID,
        ...caseData,
      },
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createInquiry = async (inquiryData: object) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/inquiry`,
      inquiryData,
      {
        headers,
        validateStatus: () => true,
      }
    );

    if (response?.status !== 200) {
      throw new Error("Failed to fetch users");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to create inquiry");
  }
};

export const updateInquiry = async (inquiryID: string, inquiryData: object) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.put(
      `${config.baseUrl}/inquiry/${inquiryID}`,
      inquiryData,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateNotifications = async (caseId: string) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.put(
      `${config.baseUrl}/notifications/${caseId}?entity=dispatcher`,
      null,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
    }
    toast.error("An error occured while updating notification.");
  }
};

export const notifyResponder = async (
  caseId: string,
  responderType: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/dispatchers/notify-responders`,
      { caseId, responderType },
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const addHospitalTransfer = async (
  hospitalId: string,
  caseId: string,
  report: string,
  notes: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/hospitals/${hospitalId}/cases`,
      { caseId, report, notes },
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const assignHospitalCase = async (
  hospitalId: string,
  caseId: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.post(
      `${config.baseUrl}/hospitals/convertcase`,
      { case_id: caseId, hospital_id: hospitalId },
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getHospitalCases = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `hospitals/cases?status=new&limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `hospitals/cases?status=new&limit=0`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch hospital cases");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch hospital cases");
  }
};

export const getCaseResponders = async (caseId: string) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.get(
      `${config.baseUrl}/cases/${caseId}/responders`,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllResponders = async (pageNumber = 1, numberPerPage = 500) => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `entities?entity=responders&skip_cache=true&numberPerPage=${numberPerPage}&pageNumber=${pageNumber}&task=trim&precinct_id=${precinct_id}`;
    } else {
      endpoint = `entities?entity=responders&skip_cache=true&numberPerPage=${numberPerPage}&pageNumber=${pageNumber}&task=trim`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch responders");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch responders");
  }
};

export const getAllHospitalsInit = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `hospitals?task=trim&precinct_id=${precinct_id}`;
    } else {
      endpoint = `hospitals?task=trim`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch hospitals");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch hospitals");
  }
};

export const getUsers = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `users?limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `users?limit=0`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch users");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch users");
  }
};

export const getUsersLookup = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `users/lookup?limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `users/lookup?limit=0`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllHospitals = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `hospitals?limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `hospitals?limit=0`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch hospitals");
    }

    if (response && response.data) {
      let { data } = response.data;

      data = data.map((item: any, index: number) => {
        if (!item.severity) {
          item.severity = "Low";
        }
        item.key = index;
        return item;
      });

      return data;
    }
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch hospitals");
  }
};

export const logout = async () => {
  try {
    Auth.signOut();
    localStorage.clear();
    toast.success(
      "Logout successful. Please login again to access the dispatch application"
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAmbulanceRequests = async (pageNumber = 1) => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `entities?entity=ambulance-requests&task=all&numberPerPage=10&pageNumber=${pageNumber}&precinct_id=${precinct_id}`;
    } else {
      endpoint = `entities?entity=ambulance-requests&task=all&numberPerPage=10&pageNumber=${pageNumber}`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch ambulance requests");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch ambulance requests");
  }
};

export const getAmbulanceRequestByID = async (ambulanceRequestId) => {
  try {
    const headers = await fetchAuthHeaders();

    const endpoint = `ambulance-requests?status=get_ambulance_request&id=${ambulanceRequestId}`;
    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createAmbulanceRequest = async (requestData) => {
  try {
    const headers = await fetchAuthHeaders();

    const { precinct_id } = await getPrecinct();
    requestData.precinct_id = precinct_id;

    const response = await axios.post(
      `${config.baseUrl}/ambulance-requests?status=create`,
      requestData,
      {
        headers,
        validateStatus: () => true,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAmbulanceRequest = async (
  ambulanceRequestId: string,
  requestData
) => {
  try {
    const headers = await fetchAuthHeaders();

    const { precinct_id } = await getPrecinct();
    requestData.precinct_id = precinct_id;

    const response = await axios.put(
      `${config.baseUrl}/ambulance-requests?status=update&id=${ambulanceRequestId}`,
      requestData,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAmbulanceDropoffLocation = async (
  ambulanceRequestId: string,
  caseId: string,
  dropoffLocationData: object
) => {
  try {
    const headers = await fetchAuthHeaders();
    const response = await axios.put(
      `${config.baseUrl}/ambulance-requests?status=update_dropoff_location&id=${ambulanceRequestId}&case_id=${caseId}`,
      dropoffLocationData,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const approveAmbulanceRequest = async (
  ambulanceRequestId,
  ambulanceProviderId
) => {
  try {
    const headers = await fetchAuthHeaders();

    const response = await axios.post(
      `${config.baseUrl}/ambulance-requests?status=approve&id=${ambulanceRequestId}`,
      { ambulance_provider_id: ambulanceProviderId },
      {
        headers,
      }
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAmbulancePayment = async (
  ambulanceRequestId: string,
  paymentStatus: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    let endpoint: string;
    if (paymentStatus === "requires_payment") {
      endpoint = `ambulance-requests?status=unconfirm_payment&id=${ambulanceRequestId}`;
    } else if (paymentStatus === "confirmed") {
      endpoint = `ambulance-requests?status=confirm_payment&id=${ambulanceRequestId}`;
    }

    const response = await axios.post(`${config.baseUrl}/${endpoint}`, null, {
      headers,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const cancelAmbulancePayment = async (
  ambulanceRequestId: string,
  requestData: object,
  requestStatus: string
) => {
  try {
    const headers = await fetchAuthHeaders();
    let endpoint: string;
    if (requestStatus === "open") {
      endpoint = `ambulance-requests?status=cancel&id=${ambulanceRequestId}`;
    } else {
      endpoint = `ambulance-requests?status=restore&id=${ambulanceRequestId}`;
    }

    const response = await axios.post(
      `${config.baseUrl}/${endpoint}`,
      requestData,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAmbulanceMetrics = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `ambulance-requests?status=get_ambulance_metrics&precinct_id=${precinct_id}`;
    } else {
      endpoint = `ambulance-requests?status=get_ambulance_metrics`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch ambulance metrics");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch ambulance metrics");
  }
};

export const getCasesMetrics = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;

      endpoint = `cases/metrics?precinct_id=${precinct_id}`;
    } else {
      endpoint = `cases/metrics`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch ambulance metrics");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch case metrics");
  }
};

export const getAmbulanceProviders = async () => {
  try {
    const headers = await fetchAuthHeaders();
    const precinctInfo = await getPrecinct();

    let endpoint: string;

    if (precinctInfo) {
      const { precinct_id } = precinctInfo;
      endpoint = `ambulance-providers?limit=0&precinct_id=${precinct_id}`;
    } else {
      endpoint = `ambulance-providers?limit=0`;
    }

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });

    if (response?.status !== 200) {
      throw new Error("Failed to fetch ambulance providers");
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch ambulance providers");
  }
};

export const getAmbulancePricing = async (
  requestCategory: string,
  isCovidCase: boolean,
  requiresOxygen: boolean
) => {
  try {
    const headers = await fetchAuthHeaders();

    let endpoint = `ambulance-requests/pricing?request_category=${requestCategory}&request_type=ambulance&covid_case=${isCovidCase}&requires_oxygen=${requiresOxygen}`;

    const response = await axios.get(`${config.baseUrl}/${endpoint}`, {
      headers,
      validateStatus: () => true,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const apiFetcher = async (
  url: string,
  data: object,
  method: "post" | "put"
) => {
  const headers = await fetchAuthHeaders();
  const response = await axios({
    method,
    url,
    data,
    headers,
    validateStatus: () => true,
  });

  if (response?.status !== 200) {
    throw new Error("Request failed");
  }

  return response?.data;
};
